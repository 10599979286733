@import "variables";

// body{
// 	padding-top: 157px;
// 	@media (min-width: $md) {
// 		padding-top: 172px;
// 	}
// }

.headerSection {
  // 	position: fixed;
  // 	top: 0;
  // 	left: 0;
  // 	width: 100%;
  // 	z-index: 16;

  @media (min-width: $md) {
    nav > ul > li > a {
      color: white !important; //default header first level override
      &:hover {
        color: $primary-color !important;
      }
    }

    .desktop-menu {
      background-color: #ffffff;
      
      .container {
        position: relative;
      }
    }
  }
}

#header {
  background-color: $primary-color;
  color: white;
  padding: 10px;

  .iconAnd {
    font-size: 1.6em;
  }
  a {
    text-decoration: none;
  }
  .container {
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .logo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: 170px;

    a {
      display: flex;
      align-items: center;

      span {
        font-weight: 300;
        color: #ffffff;
        line-height: 16px;
        font-size: 17px;

        
        @media (min-width: $md) {
          line-height: 25px;
          font-size: 30px;
          margin-left: 0;
        }
      }
    }

    .logo-anchor {
      display: block;
      font-size: 0;
      picture,
      img {
        height: 25px;
        max-width: 180px;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }
    .message {
      height: 44px;
      padding-left: 5px;
      font-size: 0.9em;
      display: flex;
      align-items: center;
      p,
      div {
        margin: 0;
        padding: 0;
      }
    }
  }
  .search {
    display: none;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 10px;

    &.desktop {
      display: none !important;
      @media (min-width: $md) {
        display: block !important;
      }
    }

    &.mobile {
      background: transparent;
      margin-top: 10px;
      @media (min-width: $md) {
        display: none !important;
      }
    }
    
    &.open {
      display: flex;
    }
    .search-wrapper {
      display: block;
      flex-direction: row;
      width: 100%;
      position: relative;
      form {
        width: 100%;
      }
      input,
      button {
        color: white;
        height: 35px;
        padding: 0;
        margin: 0;
        display: inline-block;
        border: none;
        box-shadow: none;
        background-color: $primary-color;
        &::-moz-selection,
        &::selection {
          color: white;
          background: $secondary-color;
        }
      }
      input {
        width: 100%;
        padding: 0 $general-gutter;
        font-size: 1em;
        -webkit-border-radius: $general-radius;
        -moz-border-radius: $general-radius;
        border-radius: $general-radius;
        -webkit-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        -moz-box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        box-shadow: 0px 0px 0px 1px rgba(225, 225, 225, 1);
        outline: none;
        &:hover,
        &:focus {
          outline: none;
        }
      }
      button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        width: 60px;
        padding: 0;
        margin: 0;
        border-radius: $general-radius;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          background-color: $primary-color-hover;
        }
        svg {
          fill: white;
        }
      }
    }
    .info {
      display: none;
    }
  }
  .menu {
    width: 100%;
    z-index: 2;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        &.show-compare,
        &.show-wishlist {
          display: none;
        }
        a {
          display: block;
          color: $primary-color;
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 1.4em;
          svg {
            fill: #ffffff;
          }
          &:hover {
            @media (min-width: $md) {
              background-color: white;
            }
            color: $secondary-color;
            svg {
              fill: $secondary-color;
            }
          }
          span.text {
            display: none;
          }
          .icon {
            transition: all 0.3s ease;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            transform: scale(0.75);
          }
        }
      }
    }
  }

  @media (min-width: $md) {
    .container {
      flex-direction: row;
      justify-content: center;
    }

    .right {
      position: absolute;
      right: 0;
    }

    .logo {
      padding: 0;
      padding-left: 15px;
      order: 1;
      width: auto;
      max-width: 100%;
      padding: 10px;

      .logo-anchor {
        padding-right: 15px;
        picture,
        img {
          height: 35px;
          max-width: 180px;
        }
      }
    }
    .search {
      display: flex;
      order: 2;
      background-color: transparent;
      width: auto;
      padding: 0;
      margin: 0 10px;
      .search-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;
        width: 300px;
      }
    }
    .menu {
      order: 3;
      box-shadow: none;
      width: auto;
      background: transparent;

      ul {
        li {
          &.show-search,
          &.show-menu {
            display: none;
          }
          &.show-compare,
          &.show-wishlist {
            display: block;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            svg {
              fill: white;
            }
            .icon {
              transform: scale(1);
            }
            &:hover {
              background-color: $primary-color-hover;
              color: white;
              svg {
                fill: white;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $lg) {
    .container {
      padding: 0 50px;
    }

    .search {
      .info {
        display: block;
        margin-left: 15px;
        font-size: 0.9em;
        line-height: 1.5em;
        a {
          color: white;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .menu {
      ul {
        li {
          a {
            span.text {
              display: block;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
