@import url("https://fonts.googleapis.com/css?family=Poppins:100,400,500,600,700&display=swap&subset=latin-ext");
@import "styles/variables";

body {
  height: 100vh;
  font-family: $font-family;
}

#__next {
  background: linear-gradient(
    180deg,
    rgba(251, 250, 250, 1) 0%,
    #e7e7e7 100%
  );
}

.no-scroll {
  overflow: hidden;
}

.close-icon {
  transform: rotate(45deg);
}

.phone-input-component {
  label {
    padding-left: 40px !important;

    &.label-float-custom {
      padding-left: 5px !important;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 0 10px;
}
