@import "styles/variables";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40px;
    font-size: 0.9em;
    background-color: $secondary-color;

    * {
        color:white;
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: underline;
        &:hover {
            color: #eee;
        }
    }

    @media (max-width: $sm) {
        min-height: auto;
        font-size: 0.8em;
        & > p {
            padding: 10px;
        }
      }    
}