@import "styles/variables";

.megamenu {
  .mobile {
    display: none !important; //due to override in subclases
  }
  .separator {
    display: none;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $grey-light-color;
  }
  a {
    color: $text-color;
    text-decoration: none;
    font-size: 14px;
  }
  ul {
    list-style: none;
  }

  nav {
    z-index: 999;
    width: 100%;
    padding: 0;

    ul.main {
      display: flex;
      padding: 0 10px;
      //LEVEL 0
      > li {
        &:hover {
          background: #ffffff;

          > a {
            color: $primary-color !important;
          }
        }

        > a {
          display: flex;
          align-items: center;
          padding: 20px $general-gutter * 2;
          color: $text-color !important;
          font-weight: 500;
          font-size: 14px;
        }

        @media (min-width: $md) {
          &:first-child > a {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      //LEVEL 1
      .megamenu-item {
        box-shadow: $general-shadow;
        display: none; //flex on hover
        flex-wrap: wrap;
        z-index: 4;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffffff;
        padding: $general-gutter;
        ul {
          width: 100%;
          padding: 0;
          li {
            margin-bottom: $general-gutter / 2;
            a {
              color: $text-color;
              &:hover {
                color: $primary-color !important;
              }
            }
          }
        }
        .megamenu-title {
          display: flex;
          color: $primary-color;
          font-size: 16px;
          padding: $general-gutter / 2 0;
          font-weight: bolder;
          align-items: center;
          position: relative;
          img {
            margin-right: $general-gutter;
          }
        }
      }
    }
  }
}

.column {
  width: 100%;
  position: relative;
  padding-right: $general-gutter;

  // background-image: SET INLINE ON ELEMENT
  background-repeat: no-repeat;
  background-size: 75px 75px;
  background-position: right top;
  &.menu-col-auto {
    padding: $general-gutter;

    border-left: 1px solid $grey-light-color;
    border-bottom: 1px solid $grey-light-color;

    &:nth-child(4n),
    &:last-child {
      padding-right: 1px;
      border-right: 1px solid $grey-light-color;
    }

    width: 25%;

    .megamenu-wrap {
      width: calc(100% - 75px);
      padding: 0;
      ul {
        li {
          display: inline;
          a {
            display: inline;
            margin: 0;
            padding-right: 4px;
            padding-bottom: 2px;
            font-size: 0.75em;
            &.mobile {
              display: none;
            }
          }
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 33.33%;
      background-size: 150px 150px;
      background-position: right top;
      border-top: 1px solid $grey-light-color;

      .megamenu-wrap {
        width: calc(100% - 150px);
        ul li a {
          font-size: 0.9em;
          padding-right: 4px;
          padding-bottom: 4px;
        }
      }
    }

    &:nth-child(4) {
      border-right: 1px solid $grey-light-color;
    }
  }
}
.column.menu-col-2 {
  width: 50%;
}
.column.menu-col-3 {
  width: 33%;
}
.column.menu-col-4 {
  width: 25%;
}
.column.menu-col-5 {
  width: 20%;
}
.column.menu-col-6 {
  width: 16%;
}
.megamenu-wrap {
  display: flex;
  padding-left: $general-gutter / 2;
}
@media (min-width: 900px) {
  .megamenu-title.has-submenu {
    &:after {
      display: none;
    }
  }
  .megamenu {
    ul.main {
      > li {
        &:hover {
          .megamenu-item {
            display: flex;
          }
        }
      }
    }
  }
}
@media (max-width: 899px) {
  .column.menu-col-auto:nth-child(n),
  .column.menu-col-1,
  .column.menu-col-2,
  .column.menu-col-3,
  .column.menu-col-4,
  .column.menu-col-5,
  .column.menu-col-6 {
    width: 100%;
    height: auto;
    min-height: auto;
    background-size: 75px 75px;
    background-position: right top;
    margin: 0;
    padding: 0;
  }
  .megamenu {
    .mobile,
    .mobile a {
      display: flex !important;
      width: 100%;
      padding: 0;
    }
    .separator {
      display: block;
    }

    .close {
      font-size: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      width: auto;
      color: rgba(255, 255, 255, 0.8);
    }

    nav {
      position: fixed;
      background: rgba(0, 0, 0, 0.85);
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 4;
      display: none;

      &.open {
        display: block;
      }

      ul.main {
        box-shadow: $general-shadow;
        padding: 0;
        flex-direction: column;
        overflow: auto;
        background: white;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 80vw;
        justify-content: flex-start;
        li {
          a {
            display: none;
            font-size: 14px;
            font-weight: normal;
            padding: $general-gutter;
            align-items: center;
            margin: 0;
          }
          .megamenu-title {
            display: none !important;
          }
          .megamenu-item {
            padding: 0;
            position: static;
            box-shadow: none;
            &.open {
              display: block;
            }
            .megamenu-wrap {
              display: none;
              margin-left: $general-gutter;
              &.open,
              a {
                display: flex;
              }
            }
            a {
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
            .column.menu-col-auto {
              padding: 0;
              margin-top: $general-gutter / 2;
              a {
                border-bottom: none;
              }
            }
          }
        }
        //level 0
        > li {
          > a {
            font-size: 16px;
            font-weight: 500;
            height: auto;
            padding: 10px;
            width: 100%;

            &.open {
              background: $primary-color;
              color: #ffffff !important;
            }
          }

          > div {
            background: #ffffff;

            &.open {
              a {
                padding-left: 30px;
                color: #ffffff;
                background: rgba($primary-color, 0.9);
              }
            }
          }
        }
      }
    }
  }
}
