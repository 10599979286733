$font-family: "Poppins", sans-serif;
$footer-color: #1b1a21;

$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1440px;

$general-gutter: 15px;
$general-radius: 6px;
$general-shadow: 0 0 40px rgb(0, 0, 0, 0.08);

$brand-color: #aaa;
$discount-color: rgb(230, 59, 69);
$new-color: #62fc7f;
$status-color: rgb(255, 199, 15);
$favourite-color: #50c7f7;
$gift-color: #db1ec2;

$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);

$primary-color: #e01d24;
$primary-color-hover: #d6030a;

$secondary-color: #f3f3f3;

$text-color: #030303;
$blue-color: rgb(0, 89, 255);
$grey-color: rgb(183, 183, 183);
$grey-light-color: rgb(238, 238, 238);
$grey-dark-color: rgb(172, 172, 172);

$grey-border-color: #f0f0f0;
$main-bg-color: rgb(255, 255, 255);
